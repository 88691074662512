import { type WalletClient } from "wagmi";
import { providers } from "ethers";

const walletClientToSigner = (walletClient: WalletClient) => {
	const { account, transport } = walletClient;

	const provider = new providers.Web3Provider(transport);
	const signer = provider.getSigner(account.address);
	return signer;
};

export { walletClientToSigner };
