import { cx } from "src/utils";

import React from "react";

import { type IButtonProps } from "./Button.types";
import { ButtonContextProvider } from "./ButtonContext";
import { ButtonIcon } from "./ButtonIcon";
import { ButtonText } from "./ButtonText";

const Button: React.FC<IButtonProps> & {
	Icon: typeof ButtonIcon;
	Text: typeof ButtonText;
} = (props) => {
	const {
		children,
		className,
		onClick,
		size = "md",
		type = "primary",
		disabled = false,
	} = props;

	const buttonClassnames = cx(
		"cursor-pointer group w-fit rounded-full flex flex-row justify-center items-center outline-transparent gap-2",
		// types
		type === "primary" && "bg-black rounded-md",
		type === "secondary" && "bg-gradient-red ",
		type === "gradient" && "bg-gradient-1 ",
		type === "ghost" && " bg-transparent",
		type === "outline" && " bg-white-bark bg-opacity-20 ",
		size === "md" && "h-10 px-3 py-4",
		size === "sm" && "h-9 px-5 py-4 gap-1",
		size === "wrapper" && "min-w-none",
		disabled && "cursor-not-allowed ",
		disabled && type === "primary" && "opacity-30",
		disabled && type === "outline" && "opacity-30",
		disabled && type === "ghost" && "opacity-30",
		disabled && type === "gradient" && "opacity-30",
		className,
	);

	return (
		<ButtonContextProvider value={props}>
			<button
				style={{ backgroundSize: "200%" }}
				className={buttonClassnames}
				onClick={disabled ? () => {} : onClick}
				disabled={disabled}
			>
				{children}
			</button>
		</ButtonContextProvider>
	);
};

Button.Icon = ButtonIcon;
Button.Text = ButtonText;
export { Button };
