import type { PropsWithChildren } from "react";
import React from "react";
import { Provider } from "react-redux";

import { store } from "../../redux/store";
import { Web3Provider, rainbowkitStyles } from "wallet-connection";
import { ModalsWrapper } from "./ModalsWrapper";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { cx } from "src/utils";

// @ts-ignore Prevent dead code elimination of rainbowkitStyles
window.rainbowkitStyles = rainbowkitStyles;

const AppWrappers: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<Provider store={store}>
			<Web3Provider>
				<ModalsWrapper>
					<div
						className={cx({
							"debug-screens": import.meta.env.DEV,
						})}
					>
						{children}
					</div>
				</ModalsWrapper>
				<ToastContainer
					position="bottom-right"
					autoClose={5000}
					limit={5}
					hideProgressBar={false}
					newestOnTop
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
					theme="dark"
					closeButton={false}
				/>
			</Web3Provider>
		</Provider>
	);
};

export { AppWrappers };
