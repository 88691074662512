import { Modal } from "@components/organisms/Modal";
import type { IModal } from "@components/organisms/Modal";
import React from "react";
import { useModalManager } from "@components/ethereals/ModalsWrapper";
import { Button } from "@components/molecules";
import { AnimatedIllustration } from "@components/organisms";

const SimpleErrorModal: IModal<"SimpleErrorModal"> &
	React.FC<{
		heading: string;
		errorDescription: string;
		errorMsg?: string;
		buttonText?: string;
		buttonOnClick?: () => unknown;
	}> = ({
	heading,
	errorDescription,
	errorMsg,
	buttonOnClick,
	buttonText,
}) => {
	const { modalManager } = useModalManager();

	return (
		<Modal className="relative  w-full max-w-[500px]  overflow-hidden rounded-xl bg-white">
			<Modal.Step>
				<div className="flex flex-col items-center p-5">
					<AnimatedIllustration
						animationDataPath="/failed.json"
						className="max-w-[240px]"
					/>
					<h3 className="text-black-charcoal text-center text-lg font-semibold">
						{heading}
					</h3>
					<p className="text-gray-text w-full text-center text-sm">
						{errorDescription}
					</p>
					<Button
						onClick={() => {
							modalManager.close("SimpleErrorModal");
						}}
						className="mt-4 w-full"
					>
						<Button.Text>Close</Button.Text>
					</Button>
				</div>
			</Modal.Step>
		</Modal>
	);
};

SimpleErrorModal.modalName = "SimpleErrorModal";

export { SimpleErrorModal };
