import { useChainId, useNetwork } from "wagmi";

const useBlockExplorer = () => {
	// const chainId = useChainId();
	const { chain } = useNetwork();

	// const baseUrl = `https://${chainId === 421613 ? "goerli." : ""}arbiscan.io`;
	const baseUrl =
		chain?.blockExplorers?.default?.url || "https://seistream.app/";
	const getBlockExplorer = (addressOrTxHash: string) => {
		if (addressOrTxHash.length >= 64) {
			return `${baseUrl}/tx/${addressOrTxHash}`;
		}
		return `${baseUrl}/address/${addressOrTxHash}`;
	};

	return getBlockExplorer;
};

export { useBlockExplorer };
