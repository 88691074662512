import React from "react";
import { cx } from "src/utils";

const TextSkeleton: React.FC<
	React.PropsWithChildren<{
		className?: string;
	}>
> = ({ className, children }) => {
	return (
		<p
			className={cx(
				"animate-pulse rounded-md",
				className,
				"bg-gray-200 text-transparent",
			)}
		>
			{children ? children : "-"}
		</p>
	);
};

export { TextSkeleton };
