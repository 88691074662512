import { Footer, Menu } from "@components/organisms";
import React from "react";
import { Outlet } from "react-router-dom";
import { cx } from "src/utils";

const PageTemplateWithMenuAndFooter = () => {
	const pageClassName = cx("min-h-screen flex flex-col", {
		"debug-screens": import.meta.env.NFT_MARKETPLACE_IS_PROD === "false",
	});
	return (
		<div className={pageClassName}>
			<Menu className="" />
			<div className="flex-grow">
				<Outlet />
			</div>
			<Footer className="mt-10 sm:mt-20" />
		</div>
	);
};
export { PageTemplateWithMenuAndFooter };
