import type { Wallet } from "@rainbow-me/rainbowkit";
import type { Chain } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";

export interface IMyWalletOptions {
	chains: Chain[];
}
const compassEvmWallet = ({ chains }: IMyWalletOptions): Wallet => ({
	id: "compassEvm",
	name: "Compass Wallet",
	iconUrl: "/compass.svg",
	iconBackground: "#fff",
	downloadUrls: {
		browserExtension:
			"https://chromewebstore.google.com/detail/compass-wallet-for-sei/anokgmphncpekkhclmingpimjmcooifb?hl=en-GB/?utm_source=website&utm_medium=permanent-website&utm_campaign=permanent",
	},

	createConnector: () => {
		const connector = new InjectedConnector({
			chains,
			options: {
				shimDisconnect: true,
				getProvider: () => window.ethereum,
			},
		});
		return {
			connector,
		};
	},
});

export { compassEvmWallet };
