import React from "react";
import { cx } from "src/utils";

type ASSET_TYPES = "SEI";

const getLogoFile = ({ type }: { type: ASSET_TYPES }) => {
	if (type === "SEI") {
		return "/sei-logo.png";
	}

	return "";
};

const AssetIcon: React.FC<{
	type: ASSET_TYPES;
	className?: string;
}> = ({ type, className }) => {
	return (
		<img
			src={getLogoFile({ type })}
			alt="asset logo"
			className={cx("h-6 w-6", className)}
		/>
	);
};

export { AssetIcon };
