import type { Wallet } from "@rainbow-me/rainbowkit";
import type { Chain } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";

export interface IMyWalletOptions {
	chains: Chain[];
}
const metamaskWallet = ({ chains }: IMyWalletOptions): Wallet => ({
	id: "metamask",
	name: "Metamask",
	iconUrl:
		"https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg",
	iconBackground: "#fff",
	downloadUrls: {
		browserExtension:
			"https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
		android: "https://play.google.com/store/apps/details?id=io.metamask",
		ios: "https://apps.apple.com/us/app/metamask/id1438144202",
		qrCode: "https://metamask.io/download/",
	},

	createConnector: () => {
		const connector = new MetaMaskConnector({
			chains,
			options: { shimDisconnect: true },
		});
		return {
			connector,
		};
	},
});

export { metamaskWallet };
