import React from "react";
import { PageSection } from "@components/atoms";
import { cx } from "src/utils";

const HeroSection: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<PageSection
			fullWidth
			className={cx(
				"flex h-[464px] flex-col justify-end bg-[url(/hero-bg.png)] bg-cover bg-no-repeat py-12",
				className,
			)}
		>
			<PageSection className="">
				<h2 className="text-5xl font-black text-white">
					Get Sei Testnet Tokens
				</h2>
				<p className="text-gray mt-3 max-w-[600px] text-2xl font-medium">
					Request testnet SEI tokens for the SEI testnet and test your
					EVM dapps.
				</p>
			</PageSection>
		</PageSection>
	);
};

export { HeroSection };
