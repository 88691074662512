import { PageSection } from "@components/atoms";
import React from "react";
import { cx } from "src/utils";
import { WalletConnection } from "./WalletConnection";

const Menu: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<PageSection
			className={cx(
				"flex flex-row-reverse items-center justify-between py-4",
				className,
			)}
			fullWidth={className?.includes("px-0")}
		>
			<div className="flex items-center">
				<div className="shrink-0">
					<WalletConnection />
				</div>
			</div>
			{/* <Button
				onClick={() => {
					if (asideStatus) {
						setAsideStatus(false);
					} else {
						setAsideStatus(true);
					}
				}}
				type="ghost"
				size="wrapper"
				className="hover:bg-white-bark h-12 w-12 transition-colors ease-linear hover:bg-opacity-10 md:hidden"
			>
				<Button.Icon
					type={"HAMBURGER"}
					className="stroke-white-bark h-6 w-6"
				/>
			</Button> */}
			<div>
				<img
					src="/sei-faucet-logo.png"
					alt="logo"
					className="hidden h-6 sm:block"
				/>
				<img
					src="/sei-faucet-logo-without-nima.png"
					alt="logo"
					className="block h-6 sm:hidden"
				/>
			</div>
		</PageSection>
	);
};

export { Menu };
