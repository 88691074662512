import React from "react";
import {
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromChildren,
} from "react-router-dom";
import { HomePage } from "../../Pages";
import { ScrollToTop } from "src/utils/ScrollToTop";

import { wrapCreateBrowserRouter } from "@sentry/react";
import { AuthPage } from "@components/organisms";
import { PageTemplateWithMenuAndFooter } from ".";

const routes = createRoutesFromChildren(
	<Route path="/" element={<ScrollToTop />}>
		<Route element={<PageTemplateWithMenuAndFooter />}>
			<Route path="/" element={<HomePage />} />
			<Route path="/auth" index element={<AuthPage />} />
		</Route>
	</Route>,
);

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(routes);

const AppRoutes: React.FC = () => {
	return <RouterProvider router={router} />;
};

export { AppRoutes };
