import { avalancheFujiTestnetChain } from "./avalancheFujiTestnetChain";
import { avalancheMainnetChain } from "./avalancheMainnetChain";

import { arbitrumMainnet } from "./arbitrumMainnetChain";
import { arbitrumTestnet } from "./arbitrumTestnetChain";
import { bnbMainnet } from "./bnbMainnetChain";
import { bnbTestnet } from "./bnbTestnetChain";
import { ethereumMainnet } from "./ethereumMainnetChain";
import { ethereumTestnet } from "./ethereumTestnetChain";
import { polygonMainnet } from "./polygonMainnetChain";
import { polygonTestnet } from "./polygonTestnetChain";
import { seiTestnet } from "./seiTestnetChain";
import { seiDevnet } from "./seiDevnetChain";

const getChainFromEnv = () => {
	if (
		import.meta.env.WALLET_CONNECTION_VARIANT === "ARBITRUM" ||
		import.meta.env.WALLET_CONNECTION_VARIANT === "GREEN"
	) {
		if (import.meta.env.WALLET_CONNECTION_IS_PROD === "true") {
			const currentChain = arbitrumMainnet;
			currentChain.rpcUrls.default = "https://arb1.arbitrum.io/rpc";
			return currentChain;
		}

		const currentChain = arbitrumTestnet;
		currentChain.rpcUrls.default = "https://arbitrum-goerli.publicnode.com";
		return currentChain;
	}

	if (import.meta.env.WALLET_CONNECTION_VARIANT === "AVALANCHE") {
		if (import.meta.env.WALLET_CONNECTION_IS_PROD === "true") {
			return avalancheMainnetChain;
		}

		return avalancheFujiTestnetChain;
	}

	if (import.meta.env.WALLET_CONNECTION_VARIANT === "BNB") {
		if (import.meta.env.WALLET_CONNECTION_IS_PROD === "true") {
			return bnbMainnet;
		}

		return bnbTestnet;
	}

	if (import.meta.env.WALLET_CONNECTION_VARIANT === "ETHEREUM") {
		if (import.meta.env.WALLET_CONNECTION_IS_PROD === "true") {
			return ethereumMainnet;
		}

		return ethereumTestnet;
	}

	if (import.meta.env.WALLET_CONNECTION_VARIANT === "POLYGON") {
		if (import.meta.env.WALLET_CONNECTION_IS_PROD === "true") {
			return polygonMainnet;
		}

		return polygonTestnet;
	}

	if (import.meta.env.WALLET_CONNECTION_VARIANT === "SEI") {
		return seiTestnet;
	}

	if (import.meta.env.WALLET_CONNECTION_VARIANT === "SEIDEVNET") {
		return seiDevnet;
	}

	return avalancheMainnetChain;
};

const currentChain = getChainFromEnv();

export { currentChain };
