/* eslint-disable max-lines-per-function */
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { getEndpointCreators } from "get-endpoint-creators";
import {
	claimTokens,
	getGames,
	getUserByToken,
	requestTestEth,
	verifyTestEthRequest,
} from "backend-service-faucet";

const faucetBusinessLogicApi = createApi({
	reducerPath: "faucetBusinessLogicApi",
	baseQuery: fakeBaseQuery(),
	tagTypes: [""],
	endpoints: (builder) => {
		const { createQuery, createMutation } = getEndpointCreators(builder);

		return {
			getGames: createQuery(getGames),

			requestTestEth: createMutation(requestTestEth),
			verifyTestEthRequest: createMutation(verifyTestEthRequest),
			getUserByToken: createQuery(getUserByToken),
			claimTokens: createMutation(claimTokens),
		};
	},
});

export const {
	useGetGamesQuery,
	useRequestTestEthMutation,
	useVerifyTestEthRequestMutation,
	useGetUserByTokenQuery,
	useClaimTokensMutation,
} = faucetBusinessLogicApi;

export { faucetBusinessLogicApi };
