import { currentChain } from "./chains";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
	coinbaseWallet,
	walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createConfig } from "wagmi";
import { coreWallet } from "./customWallets/coreWallet";
import { metamaskWallet } from "./customWallets/metamaskWallet";
import { publicProvider } from "wagmi/providers/public";
import { publicClientToProvider } from "./publicClientToProvider";
import { walletClientToSigner } from "./walletClientToSigner";
import { compassEvmWallet } from "./customWallets/compassEvmWallet";

const { chains, publicClient } = configureChains(
	[currentChain],
	[publicProvider()],
);

const connectors = connectorsForWallets([
	{
		groupName: "Recommended",
		wallets: [
			...(import.meta.env.WALLET_CONNECTION_IS_METAMASK_ENABLED === "true"
				? [
						// injectedWallet({ chains }),
						metamaskWallet({ chains }),
				  ]
				: []),
			...(import.meta.env.WALLET_CONNECTION_IS_COMPASS_ENABLED === "true"
				? [compassEvmWallet({ chains })]
				: []),
			...(import.meta.env.WALLET_CONNECTION_IS_CORE_ENABLED === "true"
				? [coreWallet({ chains })]
				: []),
			...(import.meta.env.WALLET_CONNECTION_IS_COINBASE_ENABLED === "true"
				? [
						coinbaseWallet({
							appName: import.meta.env.WALLET_CONNECTION_APP_NAME,
							chains,
						}),
				  ]
				: []),
			...(import.meta.env.WALLET_CONNECTION_IS_WALLET_CONNECT_ENABLED ===
			"true"
				? []
				: []),
		],
	},
]);

const wagmiConfig = createConfig({
	autoConnect: true,
	connectors,
	publicClient,
});

const getAccount = async () => {
	const connector = wagmiConfig.connector;

	if (!connector) {
		throw new Error("No wagmi connector found!");
	}

	const address = await connector.getAccount();

	return { address };
};

const getProvider = () => {
	return publicClientToProvider(wagmiConfig.publicClient);
};

const getSigner = async () => {
	const connector = wagmiConfig.connector;

	if (!connector) {
		throw new Error("No wagmi connector found!");
	}

	const walletClient = await connector.getWalletClient();

	if (!walletClient) {
		throw new Error("No signer found");
	}

	return walletClientToSigner(walletClient);
};

const disconnect = async () => {
	const connector = wagmiConfig.connector;

	if (!connector) {
		throw new Error("No wagmi connector found!");
	}

	await connector.disconnect();
};

window.getAccount = getAccount;
window.getProvider = getProvider;
window.getSigner = getSigner;
// window.ge = getAccount;

export { wagmiConfig, chains, getAccount, getProvider, getSigner, disconnect };
