import { cx } from "src/utils";

import React from "react";

import { useButtonContext } from "../ButtonContext";

const ButtonText: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
	children,
	className,
}) => {
	const { size, type } = useButtonContext();
	const textClassname = cx(
		"text-white font-medium tracking-wider whitespace-nowrap",
		type === "ghost" && " text-blue-cobalt ",
		type === "outline" && "text-white-bark",
		// sizes
		size === "md" && " text-lg",
		size === "sm" && " text-sm",
		className,
	);

	return (
		<span
			className={textClassname}
			// className="text-sm"
		>
			{children}
		</span>
	);
};

export { ButtonText };
