import React from "react";
import lottie from "lottie-web";
import { cx } from "src/utils";

const AnimatedIllustration: React.FC<{
	animationDataPath: string;
	className?: string;
}> = ({ animationDataPath, className }) => {
	const containerRef = React.useRef(null);

	React.useEffect(() => {
		if (!containerRef.current) {
			return;
		}
		const animationInstance = lottie.loadAnimation({
			container: containerRef.current,
			renderer: "svg",
			loop: false,
			autoplay: false,
			path: animationDataPath,
		});
		setTimeout(() => {
			animationInstance.play();
		}, 500);

		return () => animationInstance.destroy();
	}, []);

	return <div className={cx("", className)} ref={containerRef} />;
};

export { AnimatedIllustration };
