import React from "react";

import { chains, wagmiConfig } from "./client";
import {
	RainbowKitProvider,
	darkTheme,
	lightTheme,
} from "@rainbow-me/rainbowkit";
import { WagmiConfig, useAccount, useBalance } from "wagmi";
import { CustomAvatar } from "./avatar";

const getThemeFromEnv = () => {
	if (import.meta.env.WALLET_CONNECTION_APP_NAME === "SeiLaunch") {
		return lightTheme({
			accentColor: "#C1121F",
			overlayBlur: "small",
		});
	}
	if (import.meta.env.WALLET_CONNECTION_VARIANT === "ARBITRUM") {
		return lightTheme({
			accentColor: "#1E40AF",
			overlayBlur: "small",
		});
	}

	if (import.meta.env.WALLET_CONNECTION_VARIANT === "GREEN") {
		return lightTheme({
			accentColor: "#3CB878",
			borderRadius: "large",
			overlayBlur: "small",
		});
	}

	if (import.meta.env.WALLET_CONNECTION_VARIANT === "AVALANCHE") {
		return darkTheme({
			accentColor: "#f97316",
			overlayBlur: "small",
		});
	}

	if (
		import.meta.env.WALLET_CONNECTION_VARIANT === "BNB" ||
		import.meta.env.WALLET_CONNECTION_VARIANT === "ETHEREUM" ||
		import.meta.env.WALLET_CONNECTION_VARIANT === "SEI" ||
		import.meta.env.WALLET_CONNECTION_VARIANT === "SEIDEVNET"
	) {
		return darkTheme({
			accentColor: "#000",
			overlayBlur: "small",
		});
	}

	if (import.meta.env.WALLET_CONNECTION_VARIANT === "POLYGON") {
		return darkTheme({
			accentColor: "#000",
			overlayBlur: "small",
		});
	}

	return null;
};

const getModalSizeFromEnv = () => {
	if (import.meta.env.WALLET_CONNECTION_VARIANT === "ARBITRUM") {
		return "compact";
	}

	if (import.meta.env.WALLET_CONNECTION_VARIANT === "GREEN") {
		return "compact";
	}

	if (import.meta.env.WALLET_CONNECTION_VARIANT === "AVALANCHE") {
		return "compact";
	}

	if (import.meta.env.WALLET_CONNECTION_VARIANT === "BNB") {
		return "wide";
	}

	if (import.meta.env.WALLET_CONNECTION_VARIANT === "POLYGON") {
		return "compact";
	}

	return "wide";
};

const RealTimeBalanceWrapper: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	const { address } = useAccount();
	useBalance({ address, watch: true });
	return children;
};

const Web3Provider = ({ children }: { children: any }) => {
	return (
		<WagmiConfig config={wagmiConfig}>
			<RainbowKitProvider
				showRecentTransactions
				theme={getThemeFromEnv()}
				avatar={CustomAvatar}
				modalSize={getModalSizeFromEnv()}
				chains={chains}
			>
				<RealTimeBalanceWrapper>{children}</RealTimeBalanceWrapper>
			</RainbowKitProvider>
		</WagmiConfig>
	);
};

export { Web3Provider };
