import mockedData from "./mockedData";
import { schema } from "./schema";
import { createRequestQueryFunction } from "query-function-creators";

const claimTokens = createRequestQueryFunction({
	getAxiosRequestConfig: ({
		user,
		chainId,
	}: {
		user: string;
		chainId: number;
	}) => ({
		url: `${import.meta.env.BACKEND_SERVICE_FAUCET_BASE_URL}/claim`,
		data: {
			address: user,
			chain_id: chainId,
		},
		method: "POST",
	}),
	schema,
	getMockedData: () => mockedData.response1,
	isMockingEnabled: false,
});

export { claimTokens };
