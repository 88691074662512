import React, { useEffect } from "react";
import { Icon, PageSection } from "@components/atoms";
import { cx } from "src/utils";
import { Auth, AuthButton } from "@components/organisms";
import { Button, Input, SwitchNetworkButton } from "@components/molecules";
import { isAddress } from "ethers/lib/utils";
import { z } from "zod";
import {
	useClaimTokensMutation,
	useGetUserByTokenQuery,
} from "business-logic-faucet";
import { useAccount, useNetwork } from "wagmi";
import { currentChain, useRainbowConnectModal } from "wallet-connection";
import { useModalManager } from "@components/ethereals/ModalsWrapper";

const WrongNetworkAlert = () => {
	const { chain } = useNetwork();

	if (chain?.id === currentChain.id) return null;
	return (
		<div className="bg-orange rounded-xl p-4">
			<div className="flex items-start gap-2">
				<Icon type="INFO" className="mt-1 w-5 stroke-black" />
				<div className="flex flex-col gap-3">
					<p className="text-lg">
						Your wallet isn't connected to SEI testnet.
					</p>
					<SwitchNetworkButton
						className="w-max"
						networkId={currentChain.id}
					/>
				</div>
			</div>
		</div>
	);
};

const RequestTokensSection: React.FC<{ className?: string }> = ({
	className,
}) => {
	const { modalManager } = useModalManager();
	const [userAddress, setUserAddress] = React.useState<string>("");
	const zodSchemaUserAddress = z.custom<string>(isAddress, "Invalid Address");
	const valUserAddress = zodSchemaUserAddress.safeParse(userAddress);
	const [claimTokens, claimTokensResult] = useClaimTokensMutation();
	const { address, isConnected } = useAccount();
	const { openConnectModal } = useRainbowConnectModal();
	const { data, isSuccess } = useGetUserByTokenQuery({});

	React.useEffect(() => {
		if (claimTokensResult.isError) {
			modalManager.open("SimpleErrorModal", {
				heading: "Something went wrong",
				errorDescription:
					claimTokensResult.error?.error?.error?.message ||
					"Unknown error.",
			});
		}
	}, [claimTokensResult.isError]);

	React.useEffect(() => {
		if (claimTokensResult.isSuccess) {
			modalManager.open("SuccessSimpleModal", {
				headingText: "Success",
				descriptionText: `You requested test SEI tokens.`,
			});
		}
	}, [claimTokensResult.isSuccess]);

	useEffect(() => {
		if (address) {
			setUserAddress(address);
		}
	}, [isConnected]);

	if (!isConnected) {
		return (
			<PageSection
				className={cx(
					"border-light-gray flex h-[342px] flex-col items-center justify-center gap-3 rounded-3xl border bg-[linear-gradient(0deg,rgba(255,255,255,0.10)_19.03%,rgba(243,228,205,_0.70)_80.97%)] p-6",
					className,
				)}
			>
				<p className="text-center text-3xl font-bold">
					Testnet SEI covers transactions for testing purposes
				</p>
				<p className="text-gray max-w-[632px] text-center">
					Funds that you receive through our faucet are not real
					funds, To get testnet SEI, please connect to your wallet.
				</p>
				<Button onClick={openConnectModal}>
					<Button.Text>Connect Wallet</Button.Text>
				</Button>
			</PageSection>
		);
	}

	return (
		<PageSection
			className={cx(
				"border-light-gray rounded-3xl border p-6",
				className,
			)}
		>
			<WrongNetworkAlert />
			<Input
				type="text"
				value={userAddress}
				// setValue={setUserAddress}
				placeholder="0x.."
				name={"Wallet address"}
				wrapperClassName="w-full mt-4"
				className="text-gray h-10 max-w-[400px] border-[#E5E7EB]"
			>
				<Input.Label
					className="text-sm font-medium text-black"
					name="Wallet address"
				></Input.Label>
			</Input>

			<p className="mt-5 text-sm font-medium">Request type</p>
			<div className="border-orange mt-1 flex h-10 max-w-[170px] items-center rounded-md border bg-[#FFFCF7] px-3">
				<p>2 SEI</p>
			</div>

			<p className="mt-5 text-sm font-medium">Verify you're human</p>
			<AuthButton className="mt-1" />

			<button
				onClick={() =>
					claimTokens({ chainId: 713715, user: userAddress })
				}
				className="mt-4 flex h-10 items-center gap-1 rounded-lg bg-black px-3"
				disabled={!valUserAddress.success || !isSuccess}
			>
				<p className="text-white">Send Request</p>
			</button>

			<div className="mt-6 flex gap-1">
				<Icon type="INFO" className="h-5 w-5 stroke-black" />
				<p>You can request 2 SEI tokens every 24 hrs!</p>
			</div>
		</PageSection>
	);
};

export { RequestTokensSection };
