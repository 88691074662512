import { Modal } from "@components/organisms/Modal";
import type { IModal } from "@components/organisms/Modal";
import React from "react";
import { useModalManager } from "@components/ethereals/ModalsWrapper";
import { Button } from "@components/molecules";
import { AnimatedIllustration } from "@components/organisms";

const SuccessSimpleModal: IModal<"SuccessSimpleModal"> &
	React.FC<{
		headingText: string;
		descriptionText: string;
	}> = ({ headingText, descriptionText }) => {
	const { modalManager } = useModalManager();

	return (
		<Modal className="relative  w-full max-w-[500px]  overflow-hidden rounded-xl bg-white ">
			<Modal.Step>
				<div className="flex flex-col items-center p-5">
					<AnimatedIllustration
						animationDataPath="/success.json"
						className="max-w-[240px]"
					/>
					<h3 className="text-center text-lg font-semibold text-black">
						{headingText}
					</h3>
					<p className="text-gray text-center text-sm">
						{descriptionText}
					</p>
					<Button
						onClick={() => {
							modalManager.close("SuccessSimpleModal");
						}}
						className="mt-4 w-full"
					>
						<Button.Text>Close</Button.Text>
					</Button>
				</div>
			</Modal.Step>
		</Modal>
	);
};

SuccessSimpleModal.modalName = "SuccessSimpleModal";

export { SuccessSimpleModal };
