import { z } from "zod";

const schema = z.object({
	FAUCET_IS_PROD: z.string(),
	SENTRY_DSN: z.string(),
	SENTRY_AUTH_TOKEN: z.string(),
});

const validateEnvironment = () => {
	schema.passthrough().parse(import.meta.env);
};

export { validateEnvironment };
