import React from "react";

import { configureModalManager } from "@components/organisms/Modal";
import {
	SimpleErrorModal,
	SuccessSimpleModal,
} from "@components/organisms/Modals";

const { ModalManager, useModalManager } = configureModalManager([
	SimpleErrorModal,
	SuccessSimpleModal,
]);

const ModalsDebugWrapper: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	const { modalManager } = useModalManager();

	React.useEffect(() => {
		window.modalManager = modalManager;
	}, [modalManager]);

	return <React.Fragment>{children}</React.Fragment>;
};

const ModalsWrapper: React.FC<React.PropsWithChildren> = ({ children }) => (
	<ModalManager>
		<ModalsDebugWrapper>{children}</ModalsDebugWrapper>
	</ModalManager>
);

export { ModalsWrapper, useModalManager };
