import { Chain } from "wagmi";

const seiDevnet = {
	id: 713715,
	name: "Sei Devnet",
	network: "sei",
	nativeCurrency: {
		decimals: 18,
		name: "Sei",
		symbol: "SEI",
	},
	rpcUrls: {
		public: {
			http: [
				"https://evm-rpc-arctic-1.sei-apis.com",
				"https://evm-rpc-arctic-1.sei-apis.com",
			],
		},
		default: {
			http: [
				"https://evm-rpc-arctic-1.sei-apis.com",
				"https://evm-rpc-arctic-1.sei-apis.com",
			],
		},
	},
	blockExplorers: {
		default: {
			name: "Sei Stream",
			url: "https://seistream.app",
		},
	},
} as const satisfies Chain;

export { seiDevnet };
