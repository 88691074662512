import { z } from "zod";

const environmentSchema = z.object({
	WALLET_CONNECTION_IS_PROD: z.union([z.literal("true"), z.literal("false")]),
	WALLET_CONNECTION_APP_NAME: z.string(),
	WALLET_CONNECTION_VARIANT: z.union([
		z.literal("AVALANCHE"),
		z.literal("ARBITRUM"),
		z.literal("GREEN"),
		z.literal("BNB"),
		z.literal("ETHEREUM"),
		z.literal("POLYGON"),
		z.literal("SEI"),
		z.literal("SEIDEVNET"),
	]),
	WALLET_CONNECTION_IS_METAMASK_ENABLED: z.union([
		z.literal("true"),
		z.literal("false"),
	]),
	WALLET_CONNECTION_IS_COMPASS_ENABLED: z.union([
		z.literal("true"),
		z.literal("false"),
	]),
	WALLET_CONNECTION_IS_COINBASE_ENABLED: z.union([
		z.literal("true"),
		z.literal("false"),
	]),
	WALLET_CONNECTION_IS_WALLET_CONNECT_ENABLED: z.union([
		z.literal("true"),
		z.literal("false"),
	]),
	WALLET_CONNECTION_IS_CORE_ENABLED: z.union([
		z.literal("true"),
		z.literal("false"),
	]),
});

const validateEnvironment = () => {
	environmentSchema.passthrough().parse(import.meta.env);
};

export { validateEnvironment, environmentSchema };
