import React from "react";
import { useAccount, useChainId, useNetwork } from "wagmi";
import { WalletConnectedPopover } from "./WalletConnectedPopover/WalletConnectedPopover";
import { Button } from "@components/molecules";
import { useConnectModal, useChainModal } from "@rainbow-me/rainbowkit";

const WalletConnection = () => {
	const { address } = useAccount();
	const { openConnectModal } = useConnectModal();
	const { openChainModal } = useChainModal();

	const { chain: networkChain } = useNetwork();
	const chainId = useChainId();
	const isCorrectNetwork = networkChain?.id === chainId;

	if (address && !isCorrectNetwork) {
		return (
			<Button
				className="h-fit bg-[linear-gradient(146deg,#780000_-229.85%,#C1121F_90.1%)] px-3 py-2 text-base font-normal"
				onClick={openChainModal}
			>
				<Button.Text className="text-base text-white">
					Wrong network
				</Button.Text>
			</Button>
		);
	}

	if (!address) {
		return (
			<Button
				onClick={openConnectModal}
				className="h-fit bg-black px-3 py-2 text-base font-normal"
			>
				<Button.Text className="text-white">Connect wallet</Button.Text>
			</Button>
		);
	}

	return <WalletConnectedPopover />;
};

export { WalletConnection };
