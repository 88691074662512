/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { truncateEthAddress } from "formatting-service";
import { Popover } from "@headlessui/react";
import { Avatar, Button, TooltipNumberWrapper } from "@components/molecules";
import { AssetIcon, Icon, Tooltip } from "@components/atoms";
import { cx } from "src/utils";
import { useAccount, useBalance, useDisconnect } from "wagmi";
import { useCopyToClipboard } from "usehooks-ts";
import { Link } from "react-router-dom";
import { useBlockExplorer } from "src/hooks";

const TETHER_ADDRESS = "0xF983afa393199D6902a1Dd04f8E93465915ffD8B";

const WalletConnectedPopover = () => {
	const { address } = useAccount();
	const { disconnect } = useDisconnect();
	const [copyValueText, setCopyValueText] = React.useState("Copy");

	const handleCopy = () => {
		copy(address!);
		// set a timeout to reset the copy value text
		setCopyValueText("Copied!");
		setTimeout(() => {
			setCopyValueText("Copy");
		}, 3000);
	};

	const handleDisconnect = () => {
		disconnect();
	};

	const { data: usdtBalance } = useBalance({
		address,
		token: TETHER_ADDRESS,
	});

	const { data: nativeBalance } = useBalance({
		address,
	});

	const [copiedValue, copy] = useCopyToClipboard();

	const getBlockExplorer = useBlockExplorer();

	return (
		<Popover className="z-modal relative">
			{({ open, close }) => (
				<>
					<Popover.Button className=" flex flex-row items-center gap-2 rounded-full bg-white px-3 py-2">
						<Avatar
							userAddress={address!}
							className="h-6 w-6 ring-2 ring-black"
						/>
						<span className="">{truncateEthAddress(address!)}</span>
						<Icon
							type="CHEVRON_DOWN"
							className={cx(
								"ml-2 h-4 w-4 stroke-black transition-all duration-75",
								{ "rotate-180": open },
							)}
						/>
					</Popover.Button>

					<Popover.Panel className="absolute right-0 z-[9999] mt-2 grid min-w-[322px] grid-cols-2 gap-x-1 gap-y-2 rounded-lg bg-white p-3 ring-1 ring-[#334155]">
						<div
							className="bg-purple-black
                        col-span-2 grid grid-cols-2 gap-x-1 gap-y-3 rounded-[4px] p-2"
						>
							<div className="col-span-2 flex flex-row items-center gap-2 place-self-center">
								<Avatar
									userAddress={address!}
									className="h-6 w-6 ring-2 ring-black"
								/>
								{truncateEthAddress(address!)}
							</div>
							<button
								onClick={handleCopy}
								className="flex flex-col items-center justify-center gap-1"
							>
								<Icon
									type="COPY"
									className="h-4 w-4 stroke-black"
								/>
								<span className="text-xs">{copyValueText}</span>
							</button>
							<a
								href={getBlockExplorer(address!)}
								target="_blank"
								className="flex flex-col items-center justify-center gap-1"
								rel="noreferrer"
							>
								<Icon
									type="EXTERNAL_LINK"
									className="h-4 w-4 stroke-black"
								/>
								<span className="text-xs">
									View on explorer
								</span>
							</a>
						</div>
						<div
							className="
                    flex items-center gap-2 rounded-[4px] p-2"
						>
							<AssetIcon type={"SEI"} className="h-8 w-8" />
							<div>
								<p className="text-sm">
									<TooltipNumberWrapper
										number={nativeBalance?.formatted}
										formatPriceConfig={{
											decimals: 4,
										}}
									/>
								</p>
								<p className="text-xs text-gray-500">SEI</p>
							</div>
						</div>

						<Button
							type="ghost"
							onClick={() => {
								handleDisconnect();
								close();
							}}
							className="hover:bg-purple-medium-dark col-span-2 w-full justify-start rounded-[4px] p-2"
						>
							<Button.Icon
								type="LEAVE"
								className="h-6 w-6 stroke-[#94A3B8] stroke-[1px]"
							/>
							<Button.Text className="text-sm font-normal text-[#94A3B8]">
								Disconnect
							</Button.Text>
						</Button>
					</Popover.Panel>
				</>
			)}
		</Popover>
	);
};

export { WalletConnectedPopover };
