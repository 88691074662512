import type { schema } from "./schema";
import type { z } from "zod";

type ModelType = z.infer<typeof schema>;

const response1: ModelType = {
	status: "ok",
	user: {
		id: 1,
		email: "miticm97@gmail.com",
		name: "Milan Mitic",
	},
};

export default { response1 };
