import React from "react";
import { PageSection } from "@components/atoms";
import { cx } from "src/utils";

const config: {
	title: string;
	description: string;
	imageUrl: string;
}[] = [
	{
		title: "Axelar",
		description: "Infrastructure",
		imageUrl: "/axelar-logo.png",
	},
	{
		title: "Pyth",
		description: "Oracle",
		imageUrl: "/pyth-logo.png",
	},
	{
		title: "Dragon Swap",
		description: "DeFi",
		imageUrl: "/dragonswap-logo.png",
	},
	{
		title: "Gamblino",
		description: "Prediction Markets & Gaming",
		imageUrl: "/gamblino-logo.png",
	},
	{
		title: "NIMA",
		description: "DeFi",
		imageUrl: "/nima-logo.png",
	},
	{
		title: "Seijin",
		description: "Fundraising",
		imageUrl: "/seijin.png",
	},
	{
		title: "Kargo",
		description: "Prediction Markets",
		imageUrl: "/kargo-logo.png",
	},
	{
		title: "Nitro",
		description: "Infrastructure",
		imageUrl: "/nitro-logo.png",
	},
];

const Card: React.FC<(typeof config)[number] & { className?: string }> = ({
	title,
	description,
	imageUrl,
	className,
}) => (
	<div className={cx("flex flex-col items-center gap-1", className)}>
		<img className="h-12 w-12" src={imageUrl} alt="" />
		<h4 className="text-center text-xl font-medium">{title}</h4>
		<p className="text-gray text-center">{description}</p>
	</div>
);

const WhosBuildingOnSeiSection: React.FC<{ className?: string }> = ({
	className,
}) => {
	return (
		<PageSection className={cx("", className)}>
			<h3 className="text-center text-3xl font-bold">
				Who's Building on Sei?
			</h3>
			<div className="mt-10 flex flex-wrap justify-center gap-4">
				{config.map((cardConfig) => (
					<Card
						key={cardConfig.title}
						{...cardConfig}
						className="w-full max-w-[150px]"
					/>
				))}
			</div>
		</PageSection>
	);
};

export { WhosBuildingOnSeiSection };
