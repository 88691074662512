import mockedData from "./mockedData";
import { schema } from "./schema";
import { createRequestQueryFunction } from "query-function-creators";

const getUserByToken = createRequestQueryFunction({
	getAxiosRequestConfig: () => ({
		url: `https://api.faucet.nima.enterprises/api/auth/me`,
	}),
	schema,
	getMockedData: () => mockedData.response1,
	isMockingEnabled: false,
});

export { getUserByToken };
