import React from "react";
import { cx } from "src/utils";
import { PageSection } from "@components/atoms";
import { SocialsIconsBar } from "@components/molecules";

const socials: React.ComponentProps<typeof SocialsIconsBar>["socials"] = [
	{ url: "https://twitter.com/nima_global", type: "twitter" },
	{ url: "https://nima.enterprises/", type: "website" },
	{ url: "https://github.com/NIMA-Enterprises", type: "github" },
];
const Footer: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<PageSection fullWidth className={cx("bg-black pb-6 pt-10", className)}>
			<PageSection className="">
				<div className="grid grid-rows-3 flex-col place-items-center items-center justify-center gap-6 md:grid-cols-3 md:grid-rows-none md:flex-row md:gap-0">
					<div className="md:justify-self-start">
						<img
							src="/sei-faucet-logo-white.png"
							className="pointer-events-none w-[200px]"
						/>
					</div>
					<div className="md:justify-self-center">
						<SocialsIconsBar socials={socials}></SocialsIconsBar>
					</div>
					<div className="flex items-center gap-2 md:justify-self-end">
						<p className="text-sm text-white">
							Developed by Nima Enterprises
						</p>
						<img
							src="/nima-logo-with-text.png"
							className="pointer-events-none h-[25px]"
						/>
					</div>
				</div>
				<div className="bg-gray mt-8 h-px w-full opacity-40" />
				<p className=" mt-4  text-center text-sm text-white">
					© 2023 SEI Faucet. All rights reserved.
				</p>
			</PageSection>
		</PageSection>
	);
};

export { Footer };
