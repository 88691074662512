import React from "react";
import type { AvatarComponent } from "@rainbow-me/rainbowkit";
import { getAvatarUrl } from "./getAvatarUrl";

const CustomAvatar: AvatarComponent = ({ address, ensImage, size }) => {
	return ensImage ? (
		<img
			alt="Avatar"
			src={ensImage}
			width={size}
			height={size}
			style={{ borderRadius: 999 }}
		/>
	) : (
		<img
			width={size}
			height={size}
			src={getAvatarUrl(address)}
			alt="Avatar"
		/>
	);
};

export { CustomAvatar };
