import { cx } from "src/utils";

import React from "react";

import { Icon } from "@components/atoms/Icon";

type SocialTypes =
	| "twitter"
	| "discord"
	| "instagram"
	| "github"
	// | "youtube"
	| "medium"
	| "telegram"
	| "website";
type SocialsArray = {
	type: SocialTypes;
	url: string;
}[];

const getIcon = (type: SocialsArray[number]["type"]) => {
	switch (type) {
		case "twitter":
			return <Icon type="TWITTER" className="h-5 w-5 fill-white" />;
		case "medium":
			return <Icon type="MEDIUM" className="h-5 w-5 fill-white" />;
		case "github":
			return <Icon type="GITHUB" className="h-5 w-5 fill-white" />;
		case "discord":
			return <Icon type="DISCORD" className="h-5 w-5 fill-white" />;
		case "instagram":
			return <Icon type="INSTAGRAM" className="h-5 w-5 fill-white" />;
		// case "youtube":
		// 	return <Icon type="YOUTUBE" className="h-5 w-5 fill-white" />;
		case "telegram":
			return <Icon type="TELEGRAM" className="h-5 w-5 fill-white" />;
		case "website":
			return <Icon type="WEBSITE" className="h-5 w-5 stroke-white" />;
		default:
			return null;
	}
};

const SocialsIconsBar: React.FC<{
	socials?: SocialsArray;
	className?: string;
}> = ({ socials, className = "" }) => {
	if (!socials) {
		return (
			<div className={cx("flex items-center gap-6", className)}>
				<div className="h-5 w-5 animate-pulse rounded bg-gray-200"></div>
				<div className="h-5 w-5 animate-pulse rounded bg-gray-200"></div>
				<div className="h-5 w-5 animate-pulse rounded bg-gray-200"></div>
			</div>
		);
	}
	return (
		<div className={cx("", className)}>
			<ul className="flex items-center gap-6">
				{socials.map((social) => {
					const icon = getIcon(social.type);
					if (icon === null) {
						return null;
					}
					return (
						<a
							key={social.url}
							href={social.url}
							target="_blank"
							rel="noreferrer"
						>
							{icon}
						</a>
					);
				})}
			</ul>
		</div>
	);
};

export { SocialsIconsBar };
