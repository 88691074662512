import React from "react";
import { cx } from "src/utils";
import {
	BuildWithSei,
	HeroSection,
	RequestTokensSection,
	WhosBuildingOnSeiSection,
} from "./sections";

const HomePage: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<div className={cx(" ", className)}>
			<HeroSection className="" />
			<RequestTokensSection className="mt-10 sm:mt-20" />
			<BuildWithSei className="mt-10 sm:mt-20" />
			<WhosBuildingOnSeiSection className="mt-10 sm:mt-20" />
		</div>
	);
};

export { HomePage };
