import React from "react";
import { PageSection } from "@components/atoms";
import { cx } from "src/utils";

const config: {
	title: string;
	description: string;
	imageUrl: string;
	link: string;
}[] = [
	{
		title: "Start building",
		description: "Learn how to build a decentralized application on Sei",
		imageUrl: "/illustration-1.svg",
		link: "https://docs.sei.io/develop/get-started",
	},
	{
		title: "Learn about Sei",
		description: "Learn how Sei has become the Fastest Layer 1",
		imageUrl: "/illustration-2.svg",
		link: "https://docs.sei.io/learn/about-sei",
	},
	{
		title: "Nodes and Validators",
		description:
			"Documentation for running nodes and helping to secure the network",
		imageUrl: "/illustration-3.svg",
		link: "https://docs.sei.io/full-node/run-a-sei-node",
	},
	{
		title: "Ecosystem",
		description: "Explore new apps within the Sei ecosystem",
		imageUrl: "/illustration-4.svg",
		link: "https://www.sei.io/ecosystem",
	},
];

const Card: React.FC<(typeof config)[number] & { className?: string }> = ({
	title,
	description,
	imageUrl,
	className,
	link,
}) => (
	<a
		href={link}
		target="_blank"
		className={cx(
			"border-light-gray flex flex-col gap-1 rounded-2xl border px-6 py-4",
			className,
		)}
	>
		<img className="h-12 w-12" src={imageUrl} alt="" />
		<h4 className="text-xl font-medium">{title}</h4>
		<p className="text-gray">{description}</p>
	</a>
);

const BuildWithSei: React.FC<{ className?: string }> = ({ className }) => {
	return (
		<PageSection className={cx("", className)}>
			<h3 className="text-center text-3xl font-bold">Build with SEI</h3>
			<div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
				{config.map((cardConfig) => (
					<Card
						key={cardConfig.title}
						{...cardConfig}
						// className="basis-1/4"
					/>
				))}
			</div>
		</PageSection>
	);
};

export { BuildWithSei };
