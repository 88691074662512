import React from "react";
import { cx } from "src/utils";
import { useSwitchNetwork } from "wagmi";

const SwitchNetworkButton: React.FC<{
	className?: string;
	networkId: number;
}> = ({ className, networkId }) => {
	const { switchNetwork } = useSwitchNetwork();
	const handleClick = () => {
		if (switchNetwork) switchNetwork(networkId);
	};

	return (
		<button
			className={cx(
				" flex gap-1 rounded-md bg-white px-2 py-3 ",
				className,
			)}
			onClick={handleClick}
		>
			<p className="text-gray text-xs">Switch network</p>
		</button>
	);
};

export { SwitchNetworkButton };
