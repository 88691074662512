import React from "react";
import { useLocation } from "react-router-dom";
import { cx } from "src/utils";

const AuthPage: React.FC<{ className?: string }> = ({ className }) => {
	const location = useLocation();
	const locationHash = location.hash?.replace("#", "");

	if (locationHash) {
		window.localStorage.setItem("userToken", locationHash);
		window.location.href = "/";
	}
	return <div className={cx(" ", className)}></div>;
};

export { AuthPage };
