import * as React from "react";

import { AssetIcon } from "@components/atoms";
import { cx } from "src/utils";

const InputAssetIcon: typeof AssetIcon = (props) => {
	const { className, ...rest } = props;
	return (
		<AssetIcon
			className={cx("h-6 w-6 stroke-black", className)}
			{...rest}
		/>
	);
};

export { InputAssetIcon };
