import { cx } from "src/utils";

import React from "react";

import { useButtonContext } from "../ButtonContext";
import { Icon } from "@components/atoms/Icon";

const ButtonIcon: React.FC<React.ComponentProps<typeof Icon>> = (props) => {
	const { size, type } = useButtonContext();
	const iconClassName = cx(
		"w-5 h-5 stroke-white-bark stroke-[3px]",
		{
			" w-4 h-4": size === "sm",
		},
		props.className,
	);

	return <Icon className={iconClassName} type={props.type} />;
};

export { ButtonIcon };
