import { Icon } from "@components/atoms";
import { useGetUserByTokenQuery } from "business-logic-faucet";
import React from "react";
import { cx } from "src/utils";

const AuthButton: React.FC<{ className?: string }> = ({ className }) => {
	const { data, refetch, isSuccess } = useGetUserByTokenQuery({});
	const loggedIn = data?.status === "ok" && isSuccess;
	const handleClick = () => {
		if (loggedIn) {
			window.localStorage.removeItem("userToken");
			refetch();
			return;
		}
		window.location.href = `https://api.faucet.nima.enterprises/api/auth/github/redirect`;
	};
	return (
		<div className={cx(" ", className)}>
			<button
				className="flex h-10 items-center gap-1 rounded-lg bg-black px-3"
				onClick={handleClick}
			>
				<Icon type="GITHUB" className=" fill-orange -ml-1 w-6" />
				<p className="text-orange">
					{loggedIn ? "Disconect GitHub" : "Log in with GitHub"}
				</p>
			</button>
		</div>
	);
};

export { AuthButton };
