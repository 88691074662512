import rainbowkitStyles from "@rainbow-me/rainbowkit/styles.css";

import { validateEnvironment } from "./validateEnvironment";

export { rainbowkitStyles };

validateEnvironment();

export * from "./chains";
export * from "./client";
export * from "./Web3Provider";
export * from "./avatar";
export {
	ConnectButton as RainbowConnectButton,
	useConnectModal as useRainbowConnectModal,
} from "@rainbow-me/rainbowkit";
